import { API } from './constants.js'

// Helper method to naively perorm async fetch requests, using credentials written in localstorage
// Returns the resolved promise of the raw fetch request. DOES NOT HANDLE BUSINESS LOGIC!
export const submitRequest = async ({ path, headerOptions, ...requestOptions }) => {
  const headers = new Headers({ ...API.HEADERS, ...headerOptions })

  const token = JSON.parse(window.localStorage.electrac || `{}`).token
  if (token && path !== `auth/request`) headers.append('Authorization', `Bearer ${token}`)

  const input = `${API.HOST}/${path}`
  const init = { ...API.OPTIONS, ...requestOptions, headers }
  const request = new Request(input, init)

  try {
    return await fetch(request)
  }
  catch (error) {
    return { error } // only catches network traffic errors
  }
}
