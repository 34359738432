import React, { useContext } from 'react'
import { Switch, Route, Link } from 'react-router-dom'
import { List } from 'semantic-ui-react'
import { useAsyncEffect } from 'use-async-effect'

import { AppContext } from './App'
import Page from './Page'
import { CampaignDetailWrapper } from './CampaignDetail'
import './CampaignList.css'

import { submitRequest } from '../utilities/api'

export function CampaignListWrapper(props) {
  const [{ Campaigns }, setContext] = useContext(AppContext)

  useAsyncEffect(async () => {
    if (Campaigns) return
    const response = await submitRequest({ path: `campaigns`, method: `GET` })
    if (response.ok) setContext(`Campaigns`, await response.json())
    else setContext(`error`, response)
  }, [])

  if (!Campaigns) return <Page id="CampaignListPage" title="Campaigns" loading />

  return <Switch>
    <Route path="/campaigns/:campaignId" component={CampaignDetailWrapper} />
    <Route path="/campaigns" component={CampaignListPage} />
  </Switch>
}

function CampaignListPage(props) {
  const [{ Campaigns }] = useContext(AppContext)

  return <Page id="CampaignListPage" title="Campaigns">
    <List size="huge" animated divided selection link>
      {Object.values(Campaigns).map(({ CampaignId, CampaignName }) =>
        <List.Item as={Link} key={CampaignId} to={`/campaigns/${CampaignId}/contact-lists/0`} header={CampaignName} />
      )}
    </List>
  </Page>
}
