import { useState, useMemo } from 'react'
import get from "lodash/fp/get"
import set from "lodash/fp/set"

// Variant on useState that allows setting new values via a "path" as per "lodash/fp/set"
export function useDeepState(defaultValue = {}) {
  const [state, setState] = useState(() => defaultValue)
  return [state, (path, value) => setState(state => path ? set(path)(value)(state) : value)]
}

// Expensive method for applying filters and sorting to a ContactList array
// Memoizes (caches) computations in our best guess of the order of impact
export function useContactListFilter(currentContactList, { sort, filter }) {
  const validFilteredContactList = useMemo(() => (typeof currentContactList.Contacts === 'object'
    ? Object.values(currentContactList.Contacts).filter(Contact => typeof Contact === 'object')
    : []
  ), [currentContactList])

  const listFilteredContactList = useMemo(() => (validFilteredContactList
    .filter(Contact => !filter.ListId || Contact.Lists.find(({ ListId }) => `${ListId}` === filter.ListId))
  ), [validFilteredContactList, filter.ListId])

  const hasBinder = useMemo(() => Object.values(filter.Binder).filter(Boolean).length > 0, [filter.Binder])
  const binderFilteredContactList = useMemo(() => (listFilteredContactList
    .filter(Contact => !hasBinder || filter.Binder[Contact.Binder])
  ), [listFilteredContactList, hasBinder, filter.Binder])

  const dueFilteredContactList = useMemo(() => (binderFilteredContactList
    .filter(Contact => !filter.Due || (Contact.NextCalltimeDate && new Date(Contact.NextCalltimeDate) < new Date()))
  ), [binderFilteredContactList, filter.Due])

  const searchFilteredContactList = useMemo(() => (dueFilteredContactList
    .filter(Contact => !filter.Search || [Contact.LastName, Contact.FirstName, Contact.Occupation, Contact.BusinessName].join(' ').match(new RegExp(filter.Search, `ig`)))
  ), [dueFilteredContactList, filter.Search])

  const sortedContactList = useMemo(() => (searchFilteredContactList
    .sort((a, b) => !get(sort.column)(a) ? 1 : !get(sort.column)(b) ? -1 : ((get(sort.column)(a) > get(sort.column)(b) ? 1 : -1) * (sort.order === `ascending` ? 1 : -1)))
  ), [searchFilteredContactList, sort])

  return sortedContactList
}

// Massages values so that our UI, forms, and api can all accept and output consistent JavaScript dates
export function getLocaleDateString(date) { return date ? new Date(date.split('T')[0] + `T00:00:00`).toLocaleDateString('en-US') : undefined }
