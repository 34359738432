import React from 'react'
import ReactDOM from 'react-dom'
import { Router, Switch, Route, Redirect } from 'react-router-dom'
import 'semantic-ui-css/semantic.min.css'

import App, { AppHistory } from './components/App'
import { Auth } from './components/Auth';
import { CampaignListWrapper } from './components/CampaignList'
import './index.css'

ReactDOM.render(
  <App>
    <Router history={AppHistory}>
      <Switch>
        <Route path="/auth" component={Auth} />
        <Route path="/campaigns" component={CampaignListWrapper} />
        <Redirect to="/campaigns" />
      </Switch>
    </Router>
  </App>,
  window.app
)
