import React, { useEffect } from 'react'
import { createBrowserHistory } from 'history'

import './App.css'

import { DEFAULT_CONTEXT } from '../utilities/constants'
import { useDeepState } from '../utilities/helpers'

export const AppContext = React.createContext(DEFAULT_CONTEXT)
export const AppHistory = createBrowserHistory()

export default function App({ children }) {
  const context = useDeepState(DEFAULT_CONTEXT)

  useEffect(() => {
    const [{ error }, setContext] = context
    if (error && error.status === 401) {
      setContext(``, {})
      AppHistory.push(`/auth`)
    }
  }, [context])

  return <AppContext.Provider children={children} value={context} />
}
