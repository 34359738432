import React, { useContext } from 'react'
import { Switch, Route, Redirect, Link } from 'react-router-dom'
import { List } from 'semantic-ui-react'
import { useAsyncEffect } from 'use-async-effect'

import { AppContext } from './App'
import Page from './Page'
import { ContactListWrapper } from './ContactList'
import './CampaignDetail.css'

import { submitRequest } from '../utilities/api'

export function CampaignDetailWrapper({ match: { params: { campaignId } }, ...props }) {
  const [{ Campaigns: { [campaignId]: currentCampaign } }, setContext] = useContext(AppContext)

  useAsyncEffect(async () => {
    if (currentCampaign.ContactLists) return
    const response = await submitRequest({ path: `campaigns/${campaignId}/contact-lists`, method: `GET` })
    if (response.ok) setContext(`Campaigns.${campaignId}.ContactLists`, await response.json())
    else setContext(`error`, response)
  }, [campaignId])

  useAsyncEffect(async () => {
    if (currentCampaign.CALL_TYPE) return
    const response = await submitRequest({ path: `campaigns/${campaignId}/resources/?resourcetype=CALL_TYPE`, method: `GET` })
    if (response.ok) {
      const resource = Object.values(await response.json()).map(({ Display: text, Value: value }) => ({ text, value }))
      setContext(`Campaigns.${campaignId}.CALL_TYPE`, resource)
    } else setContext(`error`, response)
  }, [campaignId])

  useAsyncEffect(async () => {
    if (currentCampaign.CALLER) return
    const response = await submitRequest({ path: `campaigns/${campaignId}/resources/?resourcetype=CALLER`, method: `GET` })
    if (response.ok) {
      const resource = Object.values(await response.json()).map(({ Display: text, Value: value }) => ({ text, value }))
      setContext(`Campaigns.${campaignId}.CALLER`, resource)
    } else setContext(`error`, response)
  }, [campaignId])

  useAsyncEffect(async () => {
    if (currentCampaign.NAME_TYPE) return
    const response = await submitRequest({ path: `campaigns/${campaignId}/resources/?resourcetype=NAME_TYPE`, method: `GET` })
    if (response.ok) {
      const resource = Object.values(await response.json()).map(({ Display: text, Value: value }) => ({ text, value }))
      setContext(`Campaigns.${campaignId}.NAME_TYPE`, resource)
    } else setContext(`error`, response)
  }, [campaignId])

  if (!currentCampaign) return <Redirect to="/campaigns" />
  if (!currentCampaign.ContactLists) return <Page id="CampaignDetailPage" title="Campaign" loading />

  return <Switch>
    <Route path="/campaigns/:campaignId/contact-lists/:contactListId" component={ContactListWrapper} />
    <Route path="/campaigns/:campaignId" component={CampaignDetailPage} />
  </Switch>
}

function CampaignDetailPage({ match: { params: { campaignId } }, ...props }) {
  const [{ Campaigns: { [campaignId]: currentCampaign } }] = useContext(AppContext)

  const PageTitle = <><Link to="/campaigns" title="Back to Campaigns List">«</Link> Campaign: {currentCampaign.CampaignName}</>

  return <Page id="CampaignDetailPage" title={PageTitle} loading={!currentCampaign.ContactLists}>
    <List size="huge" animated divided selection link>
      {Object.values(currentCampaign.ContactLists)
        .sort(({ ListName: a }, { ListName: b }) => a.localeCompare(b))
        .map(({ ListId, ListName }) =>
          <List.Item as={Link} key={ListId} to={`/campaigns/${campaignId}/contact-lists/${ListId}`} header={ListName} />
        )}
    </List>
  </Page >
}
