import React, { useContext, useState } from 'react'
import { Header, Segment, Icon, Confirm } from 'semantic-ui-react'

import { AppContext, AppHistory } from './App'

import l10n from '../utilities/l10n'
import './Page.css'

export default function Page({ children, title, ...props }) {
  const [{ token }, setContext] = useContext(AppContext)
  const [showConfirm, setShowConfirm] = useState(false)

  function handleConfirmToggle() { setShowConfirm(!showConfirm) }
  function handleLogOut() {
    setContext(``, {})
    AppHistory.push(`/auth`)
  }

  return <>
    <Segment as="header" vertical clearing className="PageHeader">
      <Header as="h1" size="huge" content={title} className="PageTitle" />
      <Header as="div" size="huge" content={l10n.HEADER_TITLE} className="PageLogo" />
      <Icon name="sign in" size="big" link hidden={token} title="Sign in to CallTime Online" onClick={handleLogOut} />
      <Icon name="sign out" size="big" link hidden={!token} title="Sign out of CallTime Online" onClick={handleConfirmToggle} />
      <Confirm open={showConfirm} header="Sign out of CallTime Online" onConfirm={handleLogOut} onCancel={handleConfirmToggle} />
    </Segment>
    <Segment as="main" vertical className="PageBody" {...props} children={children} />
  </>
}
