import React, { useContext } from 'react'
import { Form, Message } from "semantic-ui-react";

import { AppContext } from './App'
import Page from "./Page";

import { submitRequest } from '../utilities/api';

export function Auth({ history }) {
  const [, setContext] = useContext(AppContext)

  async function handleLoginSubmit({ currentTarget }) {
    const formData = Array.from(new FormData(currentTarget)).reduce((formData, [key, value]) => ({ ...formData, [key]: value }), {})
    const response = await submitRequest({ path: `auth/request`, method: `POST`, body: JSON.stringify(formData) })
    if (response.ok) {
      window.localStorage.setItem("electrac", JSON.stringify(await response.json()))
      history.push(`/campaigns`)
    }
    else setContext(`error`, response)
  }

  return <Page title="Sign In to CallTime">
    <Form onSubmit={handleLoginSubmit}>
      <Message>Enter your Electtrac credentials to authenticate.</Message>
      <Form.Input required name="username" label="User Name:" type="text" />
      <Form.Input required name="password" label="Password:" type="password" />
      <Form.Button content="Log In" />
    </Form>
  </Page>
}