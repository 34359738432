import React, { useContext, useState } from 'react'
import { Switch, Route, Redirect, Link } from 'react-router-dom'
import { Menu, Button, Form, Table, Message, Icon } from 'semantic-ui-react'
import { useAsyncEffect } from 'use-async-effect'
import set from "lodash/fp/set"

import { AppContext } from './App'
import Page from './Page'
import { ContactDetailWrapper } from './ContactDetail'
import './ContactList.css'

import { submitRequest } from '../utilities/api'
import { SELECT_OPTIONS, DEFAULT_CALL_SESSION_CONTEXT, BINDER_PROPS } from '../utilities/constants'
import { useContactListFilter, getLocaleDateString } from '../utilities/helpers'

export const ContactListUIContext = React.createContext(DEFAULT_CALL_SESSION_CONTEXT)

export function ContactListWrapper({ match: { params: { campaignId, contactListId } } }) {
  const [{ Campaigns: { [campaignId]: currentCampaign, [campaignId]: { ContactLists: { [contactListId]: currentContactList } } } }, setContext] = useContext(AppContext)
  const contactListUI = useState(() => DEFAULT_CALL_SESSION_CONTEXT)

  useAsyncEffect(async () => {
    if (currentContactList.Contacts) return
    const response = await submitRequest({ path: `campaigns/${campaignId}/contact-lists/${contactListId}/contacts`, method: `GET` })
    if (response.ok) setContext(`Campaigns.${campaignId}.ContactLists.${contactListId}.Contacts`, await response.json())
    else setContext(`error`, response)
  }, [campaignId, contactListId])

  if (!currentContactList) return <Redirect to={`/campaigns/${campaignId}`} />
  if (!currentContactList.Contacts) return <Page id="ContactListPage" title={<><Link to={`/campaigns/${campaignId}`} title={`Back to Campaign: ${currentCampaign.CampaignName}`}>«</Link> Donor List: {currentContactList.ListName}</>} loading />

  return <ContactListUIContext.Provider value={contactListUI}>
    <Switch>
      <Route path="/campaigns/:campaignId/contact-lists/:contactListId/contacts/:contactId" component={ContactDetailWrapper} />
      <Route path="/campaigns/:campaignId/contact-lists/:contactListId" component={ContactListPage} />
    </Switch>
  </ContactListUIContext.Provider>
}

function ContactListPage({ match: { params: { campaignId, contactListId } }, history }) {
  const [{ Campaigns: { [campaignId]: currentCampaign, [campaignId]: { ContactLists, ContactLists: { [contactListId]: currentContactList } } } }] = useContext(AppContext)
  const [contactListUI, setContactListUI] = useContext(ContactListUIContext)
  const { sort, filter } = contactListUI

  const filteredContactList = useContactListFilter(currentContactList, contactListUI)

  const contactListOptions = Object.values(ContactLists)
    .filter(({ ListId }) => `${ListId}` !== contactListId)
    .map(({ ListId: option, ListName: text }) => ({ text: text, key: `${option}`, value: `${option}` }))
    .sort(({ text: a }, { text: b }) => a.localeCompare(b))

  function handleChange(event, { name, value, checked }) { setContactListUI(context => set(name)(value ? value : typeof checked === "boolean" ? checked : '')(context)) }
  function handleSortColumn(name) { return () => setContactListUI(context => set(`sort`)({ column: name, order: (name === sort.column && sort.order === `ascending`) ? `descending` : `ascending` })(context)) }
  function handleStartCallTimeSession(event) { history.push(`/campaigns/${campaignId}/contact-lists/${contactListId}/contacts/${event.currentTarget.getAttribute('name')}`) }

  function ColumnHeaderCell({ name, ...props }) { return <Table.HeaderCell sorted={name === sort.column ? sort.order : null} onClick={handleSortColumn(name)} {...props} /> }

  return <Page id="ContactListPage" title={<><Link to={`/campaigns/${campaignId}`} title={`Back to Campaign: ${currentCampaign.CampaignName}`}>«</Link> Donor List: {currentContactList.ListName}</>}>
    <Menu as="nav" icon="labeled" secondary>
      <Menu.Item as="li" aria-selected={filter.Binder.TO_CALL} content={<><Button name="filter.Binder.TO_CALL" value={!filter.Binder.TO_CALL} {...BINDER_PROPS["TO_CALL"]} onClick={handleChange} />{BINDER_PROPS["TO_CALL"].title}</>} />
      <Menu.Item as="li" aria-selected={filter.Binder.CALENDAR} content={<><Button name="filter.Binder.CALENDAR" value={!filter.Binder.CALENDAR} {...BINDER_PROPS["CALENDAR"]} onClick={handleChange} />{BINDER_PROPS["CALENDAR"].title}</>} />
      <Menu.Item as="li" aria-selected={filter.Binder.LEFT_MESSAGE} content={<><Button name="filter.Binder.LEFT_MESSAGE" value={!filter.Binder.LEFT_MESSAGE} {...BINDER_PROPS["LEFT_MESSAGE"]} onClick={handleChange} />{BINDER_PROPS["LEFT_MESSAGE"].title}</>} />
      <Menu.Item as="li" aria-selected={filter.Binder.COMMIT} content={<><Button name="filter.Binder.COMMIT" value={!filter.Binder.COMMIT} {...BINDER_PROPS["COMMIT"]} onClick={handleChange} />{BINDER_PROPS["COMMIT"].title}</>} />
      <Menu.Item as="li" aria-selected={filter.Binder.RECEIVED} content={<><Button name="filter.Binder.RECEIVED" value={!filter.Binder.RECEIVED} {...BINDER_PROPS["RECEIVED"]} onClick={handleChange} />{BINDER_PROPS["RECEIVED"].title}</>} />
      <Menu.Item as="li" aria-selected={filter.Binder.NO} content={<><Button name="filter.Binder.NO" value={!filter.Binder.NO} {...BINDER_PROPS["NO"]} onClick={handleChange} />{BINDER_PROPS["NO"].title}</>} />
    </Menu>
    <Form size="large">
      <Form.Group unstackable widths={4}>
        <Form.Select name="sort.column" fluid label="Sort By…" options={SELECT_OPTIONS.SORT_COLUMN} defaultValue={sort.column} onChange={handleChange} />
        <Form.Select name="sort.order" fluid label="Sort Order…" options={SELECT_OPTIONS.SORT_ORDER} defaultValue={sort.order} onChange={handleChange} />
        <Form.Select name="filter.ListId" clearable fluid label="Filter By List…" options={contactListOptions} defaultValue={filter.ListId} onChange={handleChange} />
        <Form.Input name="filter.Search" type="search" fluid label="Search By Name…" defaultValue={filter.Search} onChange={handleChange} />
      </Form.Group>
      <Form.Checkbox name="filter.Due" label="Due to call today" defaultChecked={filter.Due} onChange={handleChange} />
    </Form>
    <Table selectable sortable structured singleLine unstackable striped color="black" hidden={!filteredContactList.length}>
      <Table.Header>
        <Table.Row>
          <ColumnHeaderCell name="Binder" icon="address book" />
          <ColumnHeaderCell name="NextCalltimeDate" content="To Call Date" />
          <ColumnHeaderCell name="LastName" content="Last Name" />
          <ColumnHeaderCell name="FirstName" content="First Name" />
          <ColumnHeaderCell name="Occupation" content="Occupation" />
          <ColumnHeaderCell name="BusinessName" content="Business Name" />
          <ColumnHeaderCell name="WorkPhone" content="Work Phone" />
          <ColumnHeaderCell name="HomePhone" content="Home Phone" />
          <ColumnHeaderCell name="OtherPhone" content="Other Phone" />
          <ColumnHeaderCell name="Email" content="Email" />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {filteredContactList.slice(0, 500).map(({ NameId, Binder, NextCalltimeDate, LastName, FirstName, Occupation, BusinessName, WorkPhone, HomePhone, OtherPhone, Email }) =>
          <Table.Row key={NameId} name={NameId} onClick={handleStartCallTimeSession}>
            <Table.Cell textAlign="center" content={<Icon fitted color={BINDER_PROPS[Binder].color} name={BINDER_PROPS[Binder].icon} />} />
            <Table.Cell content={getLocaleDateString(NextCalltimeDate)} title={getLocaleDateString(NextCalltimeDate)} textAlign="center" />
            <Table.Cell content={LastName} title={LastName} />
            <Table.Cell content={FirstName} title={FirstName} />
            <Table.Cell content={Occupation} title={Occupation} />
            <Table.Cell content={BusinessName} title={BusinessName} />
            <Table.Cell content={WorkPhone} title={WorkPhone} />
            <Table.Cell content={HomePhone} title={HomePhone} />
            <Table.Cell content={OtherPhone} title={OtherPhone} />
            <Table.Cell content={Email} title={Email} />
          </Table.Row>
        )}
      </Table.Body>
    </Table>
    <Message icon hidden={!!filteredContactList.length} color="grey">
      <Icon.Group size="small" style={{ marginRight: `1em` }}>
        <Icon size="large" fitted name="circle outline" />
        <Icon size="small" fitted name="info" />
      </Icon.Group>
      <Message.Header>No Results Found…</Message.Header>
    </Message>
  </Page >
}